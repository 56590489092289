import { dataUriToBlob, uploadFile } from '@/utils/file/files';
import { FileContentType, FileIdAndUrl } from '@/utils/file/type';
import { Box, Divider, Flex, Spacer, Text } from '@chakra-ui/react';
import { FC } from 'react';
import MediaSwiperWrapper from './MediaSwiperWrapper';

type FileType = {
  name: string;
  contentType: string;
  fileId: string;
  path?: string;
  src: string;
};

export type DetailDisplayFileItemProps = {
  label: string;
  files: FileType[];
  onUpdateCustomFieldFileValue?: (
    fileId: string,
    params: {
      fileId: string;
      contentType: string;
      name: string;
      src: string;
    }
  ) => Promise<void>;
  getFileUploadUrls?: (filesContentTypes: FileContentType[]) => Promise<FileIdAndUrl[]>;
};

const DetailDisplayFileItem: FC<DetailDisplayFileItemProps> = (
  props: DetailDisplayFileItemProps
) => {
  const { label, files, getFileUploadUrls, onUpdateCustomFieldFileValue } = props;

  if (files.length === 0) return null;

  return (
    <>
      <Box py='1'>
        <Flex>
          <Text fontWeight='normal' fontSize='md' color='neutral.500'>
            {label}
          </Text>
          <Spacer></Spacer>
        </Flex>

        <MediaSwiperWrapper
          mediaFiles={files}
          showRemoveFileIcon={false}
          showEditInModal={!!onUpdateCustomFieldFileValue}
          editFileHandler={async (fileId, dataUri, contentType, name) => {
            if (getFileUploadUrls) {
              const data = await getFileUploadUrls([{ contentType }]);
              const { id, url } = data[0];
              await uploadFile(url, dataUriToBlob(dataUri));

              await onUpdateCustomFieldFileValue?.(fileId, {
                fileId: id,
                contentType,
                name,
                src: dataUri,
              });
            }
          }}
        />
      </Box>
      <Divider />
    </>
  );
};

export default DetailDisplayFileItem;
